
<template>
  <div class="google-map-popup">
    <button @click="closeModal" class="close-button mt-[-5px] mr-2 md:mt-2 lg:mt-2 top-0 right-0 text-3xl font-semibold text-black-600 hover:text-gray-800">&times;</button>
    <div class="responsive-map-container">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5319.792439334798!2d12.518511216416679!3d55.90261059728728!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x465248358ccc79f5%3A0xdfa5600aa14db403!2sKokkedal%20Slot%20Copenhagen!5e0!3m2!1sen!2sdk!4v1703039507044!5m2!1sen!2sdk"
        style="border:0;"
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade">
      </iframe>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GoogleMapPopup',
  methods: {
    closeModal() {
      this.$emit('close');
    },
  },
}
</script>

<style scoped>
.google-map-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%; /* Use up to 90% of the viewport width */
  max-width: 1200px; /* But don't exceed 600px */
  z-index: 100; /* Ensure it's on top of other content */
}

.close-button {
  position: absolute;
  z-index: 101; /* Higher than the map container */
  cursor: pointer;
}

.responsive-map-container {
  position: relative;
  overflow: hidden;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
  height: 0;
  padding-right: 50px;
}

.responsive-map-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; /* Make the iframe take up all the container width */
  height: 100%; /* Make the iframe take up all the container height */
  border: 0;
}
</style>
  
<template>
    <div v-if="showRsvpModal" class="modal-overlay flex justify-center items-center">
      <div class="modal-content relative max-w-xl mx-4 p-4 dark:bg-gray-900 border dark:border-gray-700 rounded-lg space-y-4">
        <button @click="showRsvpModal = false" class="absolute top-0 right-0 m-2 text-lg font-bold text-white hover:text-red-500">
          X
        </button>
        <h1 class="text-xl text-white content-center">Wedding Response Form</h1>
        <div class="flex flex-col space-y-4">
          <div class="flex flex-col space-y-2">
            <label for="name" class="text-white">Name</label>
            <input type="text" v-model="formData.name" id="name" class="p-3 rounded-lg border border-gray-300 dark:border-gray-700 bg-gray-100 dark:bg-gray-800 text-black dark:text-white" />
          </div>
          <div class="flex flex-col space-y-2">
            <label for="email" class="text-white">Email</label>
            <input type="email" v-model="formData.email" id="email" class="p-3 rounded-lg border border-gray-300 dark:border-gray-700 bg-gray-100 dark:bg-gray-800 text-black dark:text-white" />
          </div>
          <div class="flex flex-col space-y-2">
            <label for="phone" class="text-white">Phone number</label>
            <MazPhoneNumberInput v-model="formData.phone" default-country-code="DK" :preferred-countries="['DK', 'RO']" class="dark text-white"/>
          </div>
          <div class="flex flex-col space-y-2">
            <label for="answer" class="text-white">Answer</label>
            <select v-model="formData.answer" id="answer" class="p-3 rounded-lg border border-gray-300 dark:border-gray-700 bg-gray-100 dark:bg-gray-800 text-black dark:text-white">
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
          </div>
          <div class="flex flex-col space-y-2">
            <button @click="submitForm" class="p-3 rounded-lg border border-gray-300 dark:border-gray-700 bg-gray-100 dark:bg-gray-800 text-black dark:text-white hover:bg-gray-200 dark:hover:bg-gray-900 transition duration-300 ease-in-out">Submit</button>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'RsvpComponent',
    data() {
      return {
        showRsvpModal: false,
        formData: {
          name: '',
          email: '',
          phone: '',
          answer: ''
        }
      };
    },
    methods: {
      submitForm() {
        // Handle form submission
        console.log(this.formData);
        this.showRsvpModal = false;
      }
    }
  };
  </script>
  
  <style scoped>
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000;
  }
  
  .modal-content {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  </style>
<template>
    <div class="h-screen m-0 p-0 bg-center bg-no-repeat bg-cover" :style="{ backgroundImage: 'url(' + bgImage + ')'}">
        <div class="h-screen flex flex-col lg:flex-row items-center justify-center">
            <div class="flex flex-col justify-center items-center p-4 sm:p-16">
                <button @click="setLanguage('en')" class="flex flex-col items-center drop-shadow-2xl transition ease-in-out delay-50 hover:-translate-y-1 hover:scale-110 duration-300 justify-center bg-rose-200 rounded-full border-4 border-black opacity-50 hover:opacity-100">
                <img src="@/assets/Flag_of_the_United_Kingdom.png" alt="UK-Lang Icon" class="w-32 sm:w-56 h-32 sm:h-56 max-w-full max-h-full rounded-full">
                </button>
            </div>
            <div class="flex flex-col justify-center items-center p-4 sm:p-16">
                <button @click="setLanguage('dk')" class="flex flex-col items-center drop-shadow-2xl transition ease-in-out delay-50 hover:-translate-y-1 hover:scale-110 duration-300 justify-center bg-rose-200 rounded-full border-4 border-black opacity-50 hover:opacity-100">
                <img src="@/assets/Flag_of_Denmark.png" alt="DK-Lang Icon" class="w-32 sm:w-56 h-32 sm:h-56 max-w-full max-h-full rounded-full">
                </button>
            </div>
            <div class="flex flex-col justify-center items-center p-4 sm:p-16">
                <button @click="setLanguage('ro')" class="flex flex-col items-center drop-shadow-2xl transition ease-in-out delay-50 hover:-translate-y-1 hover:scale-110 duration-300 justify-center bg-rose-200 rounded-full border-4 border-black opacity-50 hover:opacity-100">
                <img src="@/assets/Flag_of_Romania.png" alt="RO-Lang Icon" class="w-32 sm:w-56 h-32 sm:h-56 max-w-full max-h-full rounded-full">
                </button>
            </div>
        </div>
    </div>
</template>


<script>
export default {
    name: 'ChooseLanguage',
    data()
    {
        return {
            bgImage: require('@/assets/background-1-transformed1.png')
        }
    },
    methods: {
        setLanguage(language) {
            this.$i18n.locale = language;
            localStorage.setItem('selectedLanguage', language); //Saves to local storage of the browser
            console.log(`Selected language: ${language}`);
            console.log(this.$i18n.locale);
            this.$emit('language-selected'); //Emits an event to the parent component
        }
    }
}
</script>

<style scoped>
  @import 'tailwindcss/base';
  @import 'tailwindcss/components';
  @import 'tailwindcss/utilities';  
</style>

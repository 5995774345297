<template>
    <div class="relative max-w-xl mx-auto p-5 rounded-lg shadow-lg custom-color">
    <button @click="emitCloseEvent" class="absolute top-4 right-4 text-2xl font-semibold custom-color z-10">&times;</button>
    <h2 class="text-center text-2xl font-semibold mb-4"> {{$t('dress-code-guidelines')}} </h2>
    <p class="mb-3 text-left">{{$t('dress-code-intro')}}</p>
    <p class ="text-left">{{$t('dress-code-desc-1')}}<span class="font-semibold text-xl">{{$t('dress-code-desc-2')}}</span>{{$t('dress-code-desc-3')}} <span class="font-semibold text-xl"> {{$t('dress-code-desc-4')}} </span>, {{$t('dress-code-desc-5')}}
    </p>
</div>
    
</template>

<script>
import { useI18n } from 'vue-i18n';

export default {
    name: 'DressCode',
    setup() {
        const { t } = useI18n();
        return { t };
    },
    methods: {
        emitCloseEvent() {
            this.$emit('close');
        }
    },
}
</script>

<style>
.custom-color{
    -webkit-text-fill-color: rgb(233, 222, 208);
}
</style>
import { createApp } from 'vue';
import App from './App.vue';
import { createI18n } from 'vue-i18n';
import en from './i18n/en.json';
import dk from './i18n/dk.json';
import ro from './i18n/ro.json';
import 'vue-final-modal/style.css'
import 'maz-ui/styles'
import 'simplebar/dist/simplebar.css';

const i18n = createI18n({
  legacy: false,
  locale: 'en',
  messages: {
    en,
    dk,
    ro,
  },
});

const app = createApp(App);
app.use(i18n);
app.mount('#app');


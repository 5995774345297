<template>
  <div :class="divClasses">
    <component
      :is="isLink ? 'a' : 'button'"
      @click="handleClick"
      :href="isLink ? href : null"
      :class="buttonClasses"
    >
      <img :src="imgSrc" :alt="imgAlt" :class="imgClasses" />
    </component>
    <span :class="spanClasses">{{ text }}</span>
  </div>
</template>

<script>
export default {
  name: 'ButtonLayout',
  props: {
    divClasses: {
      type: String,
      required: true
    },
    buttonClasses: {
      type: String,
      required: true
    },
    imgSrc: {
      type: String,
      required: true
    },
    imgAlt: {
      type: String,
      required: true
    },
    imgClasses: {
      type: String,
      required: true
    },
    spanClasses: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: true
    },
    isLink: {
      type: Boolean,
      default: false
    },
    href: {
      type: String,
      default: ''
    }
  },
  methods: {
    handleClick(event) {
      if (this.isLink) {
        event.preventDefault(); // Prevent default link behavior
        this.$emit('link-click', this.href);
      } else {
        this.$emit('click', event);
      }
    }
  }
};
</script>

<style>
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
.button-circle {
  width: 100px;  /* Ensure width and height are equal */
  height: 100px;
  border-radius: 50%; /* Make it circular */
  display: flex;
  justify-content: center;
  align-items: center;
}
.feathered-circle {
  background: radial-gradient(circle, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
  border-radius: 50%;
}

.dark-green-btn {
  background-color: rgb(43, 72, 37);
}

.page-color-text {
  color: rgb(101, 85, 64);
}
</style>
<template>
  <link rel="stylesheet" href="//unpkg.com/leaflet@1.7.1/dist/leaflet.css" />
  
  <div class="image-bg-left"></div>
  <div class="image-bg-right"></div>
  <div class="relative">
    <div class="absolute top-4 right-4 flag" @mouseenter="isHovered = true" @mouseleave="isHovered = false">
      <div class="w-8 h-8 md:w-10 lg:w-10 md:h-10 lg:h-10 rounded-full opacity-80 hover:scale-125 transition duration-300">
        <img :src="flagImage" alt="Language Flag" class="w-full h-full rounded-full object-cover">
      </div>
      <transition name="dropdown">
        <div v-if="isHovered" class="absolute w-8 md:w-10 lg:w-10 rounded-full">
          <div v-for="(flag, index) in availableFlags" :key="index" class="w-full h-8 md:h-10 lg:h-10 mt-2">
            <img :src="flag.src" :alt="flag.alt" class="w-full h-full object-cover rounded-full cursor-pointer opacity-50 hover:opacity-100 hover:scale-125 transition duration-300" @click="changeLanguage(flag.locale)">
          </div>
        </div>
      </transition>
    </div>
  </div>
  <div ref="homeContainer" class="snap-container" @scroll="saveScrollPosition">
    <div class="snap-section">
      <div class="py-4 text-center">
        <p class="text-3xl md:text-3xl lg:text-5xl font-cormorant italic">{{$t('welcome')}}</p>
        <p class="text-5xl md:text-4xl lg:text-6xl font-bold font-cormorant italic">{{$t('names')}}</p>
        <p class="text-3xl md:text-3xl lg:text-5xl font-cormorant italic">{{$t('wedding')}}</p>
        <!-- Countdown Timer Display -->
      </div>
      <hr class="mx-auto my-8 w-2/4 border-t-2 border-gray-400"/>
      <div id="countdown" class="flex justify-center space-x-4 space-y-4">
        <div class="flex flex-col items-center py-4">
          <span class="text-lg md:text-xl lg:text-2xl font-semibold">{{ countdown.months }}</span>
          <span>{{$t('months')}}</span>
        </div>
        <div class="flex flex-col items-center">
          <span class="text-lg md:text-xl lg:text-2xl font-semibold">{{ countdown.days }}</span>
          <span>{{$t('days')}}</span>
        </div>
        <div class="flex flex-col items-center">
          <span class="text-lg md:text-xl lg:text-2xl font-semibold">{{ countdown.hours }}</span>
          <span>{{$t('hours')}}</span>
        </div>
        <div class="flex flex-col items-center">
          <span class="text-lg md:text-xl lg:text-2xl font-semibold">{{ countdown.minutes }}</span>
          <span>{{$t('minutes')}}</span>
        </div>
        <div class="flex flex-col items-center">
          <span class="text-lg md:text-xl lg:text-2xl font-semibold">{{ countdown.seconds }}</span>
          <span>{{$t('seconds')}}</span>
        </div>
      </div>
      <div class="text-center absolute bottom-0 left-0 right-0 mb-12 hide-on-mobile">
        <a href="#buttons">
          <img src="@/assets/arrow-1.png" alt="Scroll down" class="scroll-arrow mx-auto w-12 h-12 md:w-16 md:h-16 lg:w-16 lg:h-16" />
        </a>
      </div>
    </div>

    <div id="buttons" class="snap-section">
      <div id="buttons-section" class="grid-row-2 gap-y-2 sm:p-6 p-3 md:p-8 lg:p-8">
        
        <component 
        :is="isMobile ? 'MobileButtons' : 'DesktopButtons'" 
        @button-click="handleButtonClick"
        @toggle-map-visibility="mapVisibility"
        @toggle-dress-code="dressCodeVisibility"
        @toggle-wish-list="wishListVisibility"
        @save-scroll-position="handlePracticalInfo"
        @toggle-contact="handleContact"
        :desktop-config="desktopConfig"
        :mobile-config="mobileConfig"
        />


      </div>
      <div class="text-center absolute bottom-0 left-0 right-0 mb-12 hide-on-mobile">
        <a href="#timeline">
          <img src="@/assets/arrow-1.png" alt="Scroll down" class="scroll-arrow mx-auto w-12 h-12 md:w-16 md:h-16 lg:w-16 lg:h-16" />
        </a>
      </div>
    </div>
    <div id="timeline" class="snap-section">
      <img :src=timelineImage class="object-contain sm:w-full sm:h-full max-w-full max-h-full mx-auto"/>
      <div class="text-center absolute bottom-0 left-0 right-0 mb-12 hide-on-mobile">
        <a href="#rsvp">
          <img src="@/assets/arrow-1.png" alt="Scroll down" class="scroll-arrow mx-auto w-12 h-12 md:w-16 md:h-16 lg:w-16 lg:h-16" />
        </a>
      </div>
    </div>
    
    <div id="rsvp" class="snap-section flex justify-center items-center">
      <div class="flex p-4 md:text-xl font-semibold">
        {{$t('rsvp-1')}}
    </div>
    <hr class="mx-auto my-2 w-2/4 border-t-2 border-gray-400"/>
    <div class="flex md:text-xl font-semibold">
      {{$t('rsvp-2')}}
    </div>
    <div id="rsvp_countdown" class="flex justify-center space-x-4 space-y-4">
        <div class="flex flex-col items-center py-4">
          <span class="text-lg md:text-xl lg:text-2xl font-semibold">{{ rsvp_countdown.months }}</span>
          <span>{{$t('months')}}</span>
        </div>
        <div class="flex flex-col items-center">
          <span class="text-lg md:text-xl lg:text-2xl font-semibold">{{ rsvp_countdown.days }}</span>
          <span>{{$t('days')}}</span>
        </div>
        <div class="flex flex-col items-center">
          <span class="text-lg md:text-xl lg:text-2xl font-semibold">{{ rsvp_countdown.hours }}</span>
          <span>{{$t('hours')}}</span>
        </div>
        <div class="flex flex-col items-center">
          <span class="text-lg md:text-xl lg:text-2xl font-semibold">{{ rsvp_countdown.minutes }}</span>
          <span>{{$t('minutes')}}</span>
        </div>
        <div class="flex flex-col items-center">
          <span class="text-lg md:text-xl lg:text-2xl font-semibold">{{ rsvp_countdown.seconds }}</span>
          <span>{{$t('seconds')}}</span>
        </div>
      </div>
      <div class="flex flex-row justify-center items-center">
      <div class="custom-bg-rsvp relative max-w-xl mx-4 p-4 border rounded-lg space-y-4">
      <div class="flex flex-col space-y-4">
        <div class="flex flex-col">
          <label for="name" class="text-white">{{$t('rsvp-3')}}</label>
          <input type="text" v-model="formData.name" id="name" :class="nameInputClass" />
        </div>
        <div class="flex flex-col">
          <label for="email" class="text-white">{{$t('rsvp-4')}}</label>
          <input type="email" v-model="formData.email" id="email" :class="emailInputClass" />
        </div>
        <div class="flex flex-col">
          <label for="phone" class="text-white">{{$t('rsvp-5')}}</label>
          <MazPhoneNumberInput v-model="formData.phone" default-country-code="DK" :preferred-countries="['DK', 'RO']" class="
          dark 
          text-white"/>
        </div>
        <div class="flex flex-col">
          <label for="answer" class="text-white">{{$t('rsvp-6')}}</label>
          <select v-model="formData.answer" id="answer" :class="answerInputClass">
            <option value="yes">{{$t('rsvp-7')}}</option>
            <option value="no">{{$t('rsvp-8')}}</option>
          </select>
        </div>
          <div class="flex flex-col space-y-2">
            <button @click="submitForm" class="p-2 rounded-lg border border-gray-300
             dark:border-green-700 bg-green-100 dark:bg-green-800
              text-black dark:text-white hover:bg-gray-200
               dark:hover:bg-gray-900 transition duration-300
                ease-in-out">{{$t('rsvp-9')}}</button>
          </div>
        </div>
      </div>
    </div>
      </div>
    </div>


  <transition name="fadeOverlay">
    <div class="modal-overlay absolute inset-0 flex justify-center" v-if="showMap" @click="showMap = false">
      <LocationMap @close="toggleMapVisibility"/>
    </div>
  </transition>

  <transition name="fadeOverlay">
    <div class="modal-overlay absolute inset-0 flex items-center justify-center" v-if="showInvitation" @click="showInvitation = false">
    </div>
  </transition>

  <transition name="fadeModal">
    <div class="modal absolute inset-0 flex justify-center items-center" v-if="showInvitation">
      <div ref="invitationBox" class="relative max-w-full max-h-full">
        <button @click="closeModal" class="absolute top-4 right-4 text-2xl font-semibold z-10 text-black-600">&times;</button>
        <img :src="invitationImage" class="invitation-image rounded-lg"/>
      </div>
    </div>
  </transition>

  <transition name="fadeOverlay">
    <div class="modal-overlay absolute inset-0 flex items-center justify-center" v-if="showDressCode" @click="showDressCode = false">
      <div @click.stop>
        <DressCode class="custom-bg" @close="toggleDressCodeVisibility"/>
      </div>
    </div>
  </transition>

  <transition name="fadeOverlay">
    <div class="modal-overlay absolute inset-0 flex items-center justify-center" v-if="showWishList" @click="showWishList = false">
      <div @click.stop>
        <WishList class="custom-bg" @close="toggleWishListVisibility"/>
      </div>
    </div>
  </transition>

  <transition name="fadeOverlay">
    <div class="modal-overlay relative inset-0 flex items-center justify-center" v-if="showContact" @click="showContact = false">
      <div @click.stop>
        <Contact class="custom-bg relative" @close="toggleContactVisibility"/>
      </div>
    </div>
  </transition>
</template>

<script>
import axios from 'axios';
import MazPhoneNumberInput from 'maz-ui/components/MazPhoneNumberInput';
import Contact from './Contact.vue';
import DesktopButtons from './DesktopButtons.vue';
import DressCode from './DressCode.vue';
import WishList from './WishList.vue';
import LocationMap from './LocationMap.vue';
import MobileButtons from './MobileButtons.vue';
import RsvpComponent from './RsvpComponent.vue';
import SimpleBar from 'simplebar';
import 'simplebar/dist/simplebar.css';

export default {
  name: 'HomeWedding',
  components: {
    LocationMap,
    Contact,
    DressCode,
    WishList,
    MazPhoneNumberInput,
    MobileButtons,
    DesktopButtons,
    RsvpComponent
  },
  data() {
    return {
      isMobile: window.innerWidth < 768, // Adjust the breakpoint as needed
      desktopConfig: [
        {
          divClasses: "flex flex-row justify-center items-center"
        }
      ],
      mobileConfig: [
        {
          divClasses: "flex flex-row justify-center items-center"
        }
      ],
      currentComponent: 'Home',
      showInvitation: false,
      isHovered: false,
      allFlags: [
        { locale: 'en', src: require('@/assets/Flag_of_the_United_Kingdom.png'), alt: 'UK Flag' },
        { locale: 'dk', src: require('@/assets/Flag_of_Denmark.png'), alt: 'DK Flag' },
        { locale: 'ro', src: require('@/assets/Flag_of_Romania.png'), alt: 'RO Flag' }
      ],
      availableFlags: [],
      showMap: false,
      showRsvpModal: false,
      modalConfig: {},
      formData: {
        name: '',
        email: '',
        phone: '',
        answer: '',
      },
      countdown: {
        months: 0,
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      },
      rsvp_countdown:{
        months: 0,
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      },
      showDressCode: false,
      showWishList: false,
      showPracticalInfo: false,
      showFAQ: false,
      showContact: false,
    };
  },
  mounted() {
    window.addEventListener('resize', this.checkScreenSize);
    const scrollPosition = sessionStorage.getItem('scrollPosition');
    if (scrollPosition) {
      this.$refs.homeContainer.scrollTop = scrollPosition;
    }
    window.addEventListener('scroll', this.handleScroll);
    document.addEventListener('click', this.outsideClickListener);
    console.log("Click mounted" + this.showInvitation);
    this.updateAvailableFlags();
    this.modalConfig = this.getInitialValues();
    this.updateCountdown();
    this.updateRsvpCountdown();
    setInterval(this.updateCountdown, 1000); //Update countdown every second
    setInterval(this.updateRsvpCountdown, 1000);
    this.initSimpleBar();
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.checkScreenSize);
    document.removeEventListener('click', this.outsideClickListener);
    console.log("Click unmounted" + this.showInvitation);
    document.body.style.overflow = '';
    window.removeEventListener('scroll', this.handleScroll);
    this.simplebar.unMount();
  },
  computed: {
    flagImage() {
      switch (this.$i18n.locale) {
        case 'en':
          return require('@/assets/Flag_of_the_United_Kingdom.png');
        case 'dk':
          return require('@/assets/Flag_of_Denmark.png');
        case 'ro':
          return require('@/assets/Flag_of_Romania.png');
        default:
          return '';
      }
    },
    timelineImage() {
      switch (this.$i18n.locale) {
        case 'en':
          return require('@/assets/wedding-timeline-en.png');
        case 'dk':
          return require('@/assets/wedding-timeline-dk.png');
        case 'ro':
          return require('@/assets/wedding-timeline-ro.png');
        default:
          return ''; 
      }
    },
    invitationImage() {
      switch (this.$i18n.locale) {
        case 'en':
          return require('@/assets/invitation_en.png');
        case 'dk':
          return require('@/assets/invitation_dk.png');
        case 'ro':
          return require('@/assets/invitation_ro.png');
        default:
          return '';
      }
    },
    nameInputClass() {
      return {
        'p-2': true,
        'rounded-lg': true,
        'border': true,
        'rsvp-background-fields': true,
        'dark:rsvp-background-fields': true,
        'text-black': true,
        'dark:text-white': true,
        'border-green-300': this.isNameValid,
        'dark:border-green-500': this.isNameValid,
        'border-red-300': !this.isNameValid && this.formData.name.trim().length > 0,
        'dark:border-red-500': !this.isNameValid && this.formData.name.trim().length > 0
      };
    },
    isNameValid() {
      return this.formData.name.trim().length > 0;
    },
    emailInputClass() {
      return {
        'p-2': true,
        'rounded-lg': true,
        'border': true,
        'rsvp-background-fields': true,
        'dark:rsvp-background-fields': true,
        'text-black': true,
        'dark:text-white': true,
        'border-green-200': this.isEmailValid,
        'dark:border-green-500': this.isEmailValid,
        'border-red-300': !this.isEmailValid && this.formData.email.trim().length > 0,
        'dark:border-red-500': !this.isEmailValid && this.formData.email.trim().length > 0
      };
    },
    isEmailValid() {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailPattern.test(this.formData.email);
    },
    answerInputClass() {
      return {
        'p-2': true,
        'rounded-lg': true,
        'border': true,
        'rsvp-background-fields': true,
        'dark:rsvp-background-fields': true,
        'text-black': true,
        'dark:text-white': true,
        'border-green-300': this.isAnswerValid,
        'dark:border-green-500': this.isAnswerValid,
        'border-red-300': !this.isAnswerValid && this.formData.answer.trim().length > 0,
        'dark:border-red-500': !this.isAnswerValid && this.formData.answer.trim().length > 0
      };
    },
    isAnswerValid() {
      return this.formData.answer.trim().length > 0;
    },
  },
  watch: {
    '$i18n.locale': function () {
      this.updateAvailableFlags();
    }
  },
  methods: {
    checkScreenSize() {
      this.isMobile = window.innerWidth < 768;
    },
    saveScrollPosition() {
      const scrollPosition = this.$refs.homeContainer.scrollTop;
      sessionStorage.setItem('scrollPosition', scrollPosition);
    },
    mapVisibility(){
      this.showMap = true;
    },
    toggleMapVisibility() {
      this.showMap = !this.showMap;
    },
    dressCodeVisibility(){
      this.showDressCode = true;
    },
    toggleDressCodeVisibility() {
      this.showDressCode = !this.showDressCode;
    },
    wishListVisibility(){
      this.showWishList = true;
    },
    toggleWishListVisibility() {
      this.showWishList = !this.showWishList;
    },
    disableSnap() {
      this.$refs.homeContainer.style.scrollSnapType = 'none';
    },
    enableSnap() {
      this.$refs.homeContainer.style.scrollSnapType = 'y mandatory';
    },
    closeModal() {
      this.showInvitation = false;
    },
    handleButtonClick(event) {
      this[event]();
    },
    handleClick() {
      this.showInvitation = true;
      event.stopPropagation();
      console.log("Click inside" + this.showInvitation);
    },
    handleClickWish() {
      window.open("https://onskeskyen.dk/s/Y4YQA", '_blank', 'noreferrer');
    },
    handlePracticalInfo(){
      
    },
    handleFAQ(){
      
    },
    handleClickMedia(){

    },
    toggleContactVisibility(){
      this.showContact = !this.showContact;
    },
    handleContact(){
      this.showContact = true;
    },
    changeLanguage(locale) {
      this.$i18n.locale = locale;
      this.isHovered = false;
    },
    closeInvitation() {
      this.showInvitation = false;
    },
    outsideClickListener(event) {
      if (this.showInvitation && !this.$refs.invitationBox.contains(event.target)) {
        this.closeInvitation();
      }
      if (this.showContact && this.$refs.contactBox && !this.$refs.contactBox.contains(event.target)) {
        this.showContact = false;
      }
      if (this.showDressCode && this.$refs.dressCodeBox && !this.$refs.dressCodeBox.contains(event.target)) {
        this.showDressCode = false;
      }
      if (this.showWishList && this.$refs.wishListBox && !this.$refs.wishListBox.contains(event.target)) {
        this.showWishList = false;
      }
      console.log("Click outside" + this.showInvitation);
    },
    updateAvailableFlags() {
      this.availableFlags = this.allFlags.filter(flag => flag.locale !== this.$i18n.locale);
    },
    getInitialValues() {
      return {
        teleportTo: 'body',
        modelValue: false,
        displayDirective: 'if',
        hideOverlay: false,
        overlayTransition: 'vfm-fade',
        contentTransition: 'vfm-fade',
        clickToClose: true,
        escToClose: true,
        background: 'non-interactive',
        lockScroll: true,
        reserveScrollBarGap: true,
        swipeToClose: 'none',
      };
    },
    initSimpleBar() {
      this.simplebar = new SimpleBar(this.$refs.homeContainer, {
        autoHide: false,
        forceVisible: true
      });
      this.applySnapStyles();
    },
    applySnapStyles() {
      const contentEl = this.simplebar.getScrollElement();
      contentEl.style.scrollSnapType = 'y mandatory';
      contentEl.style.scrollBehavior = 'smooth';
    },
    async submitForm() {
      try {
        if (this.formData.answer === 'yes') {
          this.formData.answer = true;
        } else {
          this.formData.answer = false;
        }
        console.log(this.formData);
        const response = await axios.post('https://5gdz6vs189.execute-api.eu-north-1.amazonaws.com/dev/api/rsvp', this.formData);
        console.log(response);
        this.showRsvpModal = false;
        alert("Thank you for your response!\nYou will receive a confirmation email shortly.");
      } catch (error) {
        console.log("Error submitting form:", error);
        alert("Something went wrong!\nPlease try again later or contact us directly.");
      }
    },
    updateCountdown() {
      const weddingDate = new Date('2025-08-23T13:00:00+02:00');  // CEST (UTC+2)
      const now = new Date();
      const diff = weddingDate - now;
      if (diff <= 0) {
        clearInterval(this.updateCountdown);
        return;
      }

      let months = 0;
      let current = new Date(now);

      while (current < weddingDate) {
        current.setMonth(current.getMonth() + 1);
        if (current <= weddingDate) {
          months++;
        } else {
          current.setMonth(current.getMonth() - 1);
          break;
        }
      }

      // Now, calculate the remaining days accurately
      let days = (weddingDate - current) / (1000 * 60 * 60 * 24);

      // get total seconds between the times
      let delta = Math.abs(weddingDate - now) / 1000;

      // calculate (and subtract) whole hours
      let hours = Math.floor(delta / 3600) % 24;
      delta -= hours * 3600;

      // calculate (and subtract) whole minutes
      let minutes = Math.floor(delta / 60) % 60;
      delta -= minutes * 60;

      // what's left is seconds
      let seconds = Math.floor(delta % 60);  // the modulus is required for exact value

      this.countdown = { months, days: Math.floor(days), hours, minutes, seconds };
    },
    updateRsvpCountdown() {
      const rsvpDate = new Date('2025-04-01T10:00:00+02:00');  // CEST (UTC+2)
      const now = new Date();
      const diff = rsvpDate - now;
      if (diff <= 0) {
        clearInterval(this.updateRsvpCountdown);
        return;
      }

      let months = 0;
      let current = new Date(now);

      while (current < rsvpDate) {
        current.setMonth(current.getMonth() + 1);
        if (current <= rsvpDate) {
          months++;
        } else {
          current.setMonth(current.getMonth() - 1);
          break;
        }
      }

      // Calculate the remaining days accurately
      let days = (rsvpDate - current) / (1000 * 60 * 60 * 24);

      // get total seconds between the times
      let delta = Math.abs(rsvpDate - now) / 1000;

      // calculate (and subtract) whole hours
      let hours = Math.floor(delta / 3600) % 24;
      delta -= hours * 3600;

      // calculate (and subtract) whole minutes
      let minutes = Math.floor(delta / 60) % 60;
      delta -= minutes * 60;

      // what's left is seconds
      let seconds = Math.floor(delta % 60);  // the modulus is required for exact value

      this.rsvp_countdown = { months, days: Math.floor(days), hours, minutes, seconds };
    }
  }
};
</script>

<style scoped>
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

.simplebar-scrollbar:before {
  background-color: #444;
}
.simplebar-track.simplebar-vertical {
  width: 12px;
}
.simplebar-track.simplebar-horizontal {
  height: 12px;
}

.flag {
  z-index: 10;
}

.feathered-circle {
  background: radial-gradient(circle, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 100%);
}

.fadeOverlay-enter-active, .fadeOverlay-leave-active {
  transition: opacity .5s linear;
}
.fadeOverlay-enter-from, .fadeOverlay-leave-to {
  opacity: 0;
}

/* Modal fade transitions */
.fadeModal-enter-active, .fadeModal-leave-active {
  transition: opacity .5s linear;
}
.fadeModal-enter-from, .fadeModal-leave-to {
  opacity: 0;
}
.rsvp-background-fields{
  background: rgb(49,50,69)
}

.modal {
  position: absolute;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden; /* Ensure no overflow */
}

.invitation-image {
  max-width: 90vw; /* Scale the image to fit within the viewport width */
  max-height: 90vh; /* Scale the image to fit within the viewport height */
  object-fit: contain; /* Maintain aspect ratio and contain within the box */
  border-radius: 8px;
}




.modal-overlay {
  position: absolute;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 998;
  background: rgba(40, 39, 39, 0.514);
  backdrop-filter: blur(15px);
}

.image-bg-left, .image-bg-right {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  z-index: -1;
}

body {
  background-color: #d1d6c7;
}

.image-bg-left {
  background-image: url('@/assets/left_background.png');
  left: 0;
}

.image-bg-right {
  background-image: url('@/assets/right_background.png');
  right: 0;
}

.dropdown-enter-from, .dropdown-leave-to {
  opacity: 0;
  transform: translateY(-10px);
}

.dropdown-enter-to, .dropdown-leave-from {
  opacity: 1;
  transform: translateY(0);
}

.dropdown-enter-active, .dropdown-leave-active {
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.dark-green-btn {
  background-color: rgb(43, 72, 37);
}

.custom-bg {
  background: rgb(101, 106, 96);
}

.custom-bg-rsvp {
  background: rgba(101, 106, 96, 80%);
}

.snap-container {
  height: 100vh;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.snap-container::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}

.snap-section {
  position: relative;
  height: 100vh;
  scroll-snap-align: start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.scroll-arrow {
  cursor: pointer;
  display: inline-block;
  font-size: 2rem; /* Adjust size as needed */
  animation: bounce 1.5s infinite; /* Apply the bounce animation */
}

html {
  scroll-behavior: smooth;
}

.page-color-text {
  color: rgb(101, 85, 64);
}

.custom-rsvp-color {
  color: #efe0c9;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-20px); /* Adjust the bounce height */
  }
  60% {
    transform: translateY(-10px);
  }
}

/* Responsive adjustments for mobile devices */
@media (max-width: 1024px) {
  .simplebar-track.simplebar-vertical {
    width: 20px; /* Increase width further for mobile */
  }

  .simplebar-track.simplebar-horizontal {
    height: 20px; /* Increase height further for mobile */
  }
  .image-bg-left, .image-bg-right {
    background-size: contain;
    background-position: bottom;
    background-image: url('@/assets/try111.png');
  }
  /* Adjusts the layout of the button container to wrap on smaller screens */
  .flex.flex-row.justify-center.items-center {
    flex-wrap: wrap; /* Allows buttons to wrap to the next line */
    justify-content: space-around; /* Distributes space around items */
  }

  /* Adjusts button sizing for mobile */
  .flex.flex-col.items-center button a {
    width: auto; /* Each button takes up 30% of the container's width */
    height: auto; /* Height adjusts to the width to maintain a circle */
    margin: 1.5%; /* Adds a little margin around the buttons */
    padding-top: 30%; /* Uses padding to maintain the aspect ratio */
  }

  /* Ensures image stays centered and scales within the button */
  .flex.flex-col.items-center button img a {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  /* Adjust the language icon position for mobile */
  .relative > .absolute {
    right: 1rem; /* Keep the icon away from the edge */
    top: 1rem;
  }
}

/* Adjust button styles for larger screens to maintain the circular shape */
@media (max-width: 769px) {
  .simplebar-track.simplebar-vertical {
    width: 20px; /* Increase width further for mobile */
  }

  .simplebar-track.simplebar-horizontal {
    height: 20px; /* Increase height further for mobile */
  }
  .hide-on-mobile {
    display: none;
  }
  /* Keep the buttons in a row with evenly distributed space */
}

@media (max-height: 600px) {
  .simplebar-track.simplebar-vertical {
    width: 20px; /* Increase width further for mobile */
  }

  .simplebar-track.simplebar-horizontal {
    height: 20px; /* Increase height further for mobile */
  }
  .hide-on-mobile {
    display: none;
  }
  /* Keep the buttons in a row with evenly distributed space */
}
</style>

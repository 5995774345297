<template>
    <div class="relative max-w-xl mx-auto p-5 rounded-lg shadow-lg custom-color">
    <button @click="emitCloseEvent" class="absolute top-4 right-4 text-2xl font-semibold custom-color z-10">&times;</button>
    <h2 class="text-center text-2xl font-semibold mb-4"> {{$t('wish-list')}} </h2>
    <p class="mb-3 text-left">{{$t('wish-list-intro')}}</p>
    <p class="mb-3 text-left font-semibold">{{$t('wish-list-bank')}}</p>
    <p class="mb-3 text-left font-semibold">{{$t('wish-list-reg')}}</p>
    <p class="mb-3 text-left font-semibold">{{$t('wish-list-acc')}}</p>
    <p class="mb-3 text-left font-semibold">{{$t('wish-list-iban')}}</p>
    <p class="mb-3 text-left font-semibold">{{$t('wish-list-swift')}}</p>
    <p class="mb-3 text-left">
        {{$t('wish-list-link-info')}}
        <a href="https://onskeskyen.dk/s/djcq67" class="font-bold text-orange-600 underline" target="_blank">
            {{$t('wish-list-link')}}
        </a>.
    </p>
</div>
    
</template>

<script>
import { useI18n } from 'vue-i18n';

export default {
    name: 'WishList',
    setup() {
        const { t } = useI18n();
        return { t };
    },
    methods: {
        emitCloseEvent() {
            this.$emit('close');
        }
    },
}
</script>

<style>
.custom-color{
    -webkit-text-fill-color: rgb(233, 222, 208);
}
</style>
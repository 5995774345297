<template>
    <div class="flex flex-col justify-center items-center p-4 max-w-screen-lg mx-auto relative bg-white rounded-2xl shadow-lg">
      <button @click="emitCloseEvent" class="absolute top-4 right-4 text-2xl font-semibold custom-color z-10">&times;</button>
  
      <!-- Email Section -->
      <div class="flex flex-col justify-center items-center py-4">
        <span class="custom-color text-base md:text-lg mt-2 font-bold">Email: contact@miwadrian.dk</span>
      </div>
  
      <!-- Contact Sections -->
      <div class="grid grid-cols-2 md:flex md:flex-row gap-8 w-full">
  
        <!-- Bride Section -->
        <div class="flex flex-col justify-center items-center md:p-4 w-auto">
          <span class="custom-color text-sm md:text-lg md:mt-4 md:py-2 font-bold">Bride</span>
          <img src="@/assets/miw.jpg" alt="Invitation Icon" class="transition ease-in-out delay-50 hover:-translate-y-1 hover:scale-110 duration-300 rounded-full w-24 h-24">
          <span class="custom-color text-sm md:text-lg md:mt-2 font-semibold text-center">Miw Liv Louise B. Madsen</span>
          <div class="flex items-center md:gap-2 md:mt-2">
            <img class="w-6 h-6" src="@/assets/phone.png" alt="Phone">
            <span class="custom-color text-sm md:text-lg">+45 25 77 76 25</span>
          </div>
        </div>
  
        <!-- Groom Section -->
        <div class="flex flex-col justify-center items-center md:p-4 w-auto">
          <span class="custom-color text-sm md:text-lg md:mt-4 md:py-2 font-bold">Groom</span>
          <img src="@/assets/ady.jpg" alt="Invitation Icon" class="transition ease-in-out delay-50 hover:-translate-y-1 hover:scale-110 duration-300 rounded-full w-24 h-24">
          <span class="custom-color text-sm md:text-lg md:mt-2 font-semibold text-center">Adrian Danilescu</span>
          <div class="flex items-center md:gap-2 md:mt-2">
            <img class="w-6 h-6" src="@/assets/phone.png" alt="Phone">
            <span class="custom-color text-sm md:text-lg">+45 31 16 99 61</span>
          </div>
        </div>

        <!-- Best Man Section -->
        <div class="flex flex-col justify-center items-center md:p-4 w-auto">
          <span class="custom-color text-sm md:text-lg md:mt-4 md:py-2 font-bold text-center">Groom's Best Man</span>
          <img src="@/assets/lassie.jpg" alt="Invitation Icon" class="transition ease-in-out delay-50 hover:-translate-y-1 hover:scale-110 duration-300 rounded-full w-24 h-24">
          <span class="custom-color text-sm md:text-lg mt-2 font-semibold text-center">Evaldas Lamsargis</span>
          <div class="flex items-center md:gap-2 md:mt-2">
            <img class="w-6 h-6" src="@/assets/phone.png" alt="Phone">
            <span class="custom-color text-sm md:text-lg">+45 81 92 98 06</span>
          </div>
        </div>
  
        <!-- Toast Master Section -->
        <div class="flex flex-col justify-center items-center md:p-4 w-auto">
          <span class="custom-color text-sm md:text-lg md:mt-4 md:py-2 font-bold text-center">Toast Master</span>
          <img src="@/assets/rune.jpg" alt="Invitation Icon" class="transition ease-in-out delay-50 hover:-translate-y-1 hover:scale-110 duration-300 rounded-full w-24 h-24">
          <span class="custom-color text-sm md:text-lg mt-2 font-semibold text-center">Rune B. Madsen</span>
          <div class="flex items-center md:gap-2 md:mt-2">
            <img class="w-6 h-6" src="@/assets/phone.png" alt="Phone">
            <span class="custom-color text-sm md:text-lg">+45 44 50 79 33</span>
          </div>
        </div>
        </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'ContactInfo',
    methods: {
      emitCloseEvent() {
        this.$emit('close');
      }
    },
  }
  </script>
  
  <style>
  .custom-color {
    -webkit-text-fill-color: rgb(233, 222, 208);
  }
  </style>
  
<template>
  <div v-for="(config, index) in desktopConfig" :key="index" :class="config.divClasses">
    <div v-for="(button, bIndex) in buttonConfigs" :key="bIndex">
      <ButtonLayout
        :div-classes="button.divClasses"
        :button-classes="`${button.buttonClasses} button-circle`"
        :img-src="button.imgSrc"
        :img-alt="button.imgAlt"
        :img-classes="button.imgClasses"
        :span-classes="button.spanClasses"
        :text="button.text"
        :is-link="button.isLink"
        :href="button.href"
        @click="handleButtonClick(button.clickEvent)"
        @link-click="handleLinkClick"
        v-bind:title="button.title || null"
      />
    </div>
  </div>
  <div v-for="(config, index) in desktopConfig" :key="index" :class="config.divClasses">
    <div v-for="(button, bIndex) in buttonConfigs2" :key="bIndex">
      <ButtonLayout
        :div-classes="button.divClasses"
        :button-classes="`${button.buttonClasses} button-circle`"
        :img-src="button.imgSrc"
        :img-alt="button.imgAlt"
        :img-classes="button.imgClasses"
        :span-classes="button.spanClasses"
        :text="button.text"
        :is-link="button.isLink"
        :href="button.href"
        @click="handleButtonClick(button.clickEvent)"
        @link-click="handleLinkClick"
        v-bind:title="button.title || null"
      />
    </div>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n';
import ButtonLayout from './ButtonLayout.vue';

export default {
  name: 'DesktopButtons',
  components: {
    ButtonLayout
  },
  props: {
    desktopConfig: {
      type: Object,
      required: true
    }
  },
  emits: ['button-click', 'toggle-map-visibility', 'toggle-dress-code', 'toggle-contact', 'show-practical-info', 'save-scroll-position'],
  setup() {
    const { t } = useI18n();
    return { t };
  },
  data() {
    return {
        buttonConfigs: [],
        buttonConfigs2: []
    };
  },
  created() {
    this.initializeButtonConfigs();
  },
  computed: {
    flagImage() {
      switch (this.$i18n.locale) {
        case 'en':
          return require('@/assets/Flag_of_the_United_Kingdom.png');
        case 'dk':
          return require('@/assets/Flag_of_Denmark.png');
        case 'ro':
          return require('@/assets/Flag_of_Romania.png');
        default:
          return '';
      }
    }  
  },
  watch: {
    '$i18n.locale': function () {
      this.initializeButtonConfigs();
    }
  },
  methods: {
    handleButtonClick(clickEvent) {
      if (clickEvent === 'showMap') {
        this.$emit('toggle-map-visibility');
      } 
      else if (clickEvent === 'showDressCode'){
        this.$emit('toggle-dress-code');
      }
      else if (clickEvent === 'showWishList'){
        this.$emit('toggle-wish-list');
      }
      else if (clickEvent === 'showContact'){
        this.$emit('toggle-contact');
      }
      else {
        this.$emit('button-click', clickEvent);
      }
    },
    handleLinkClick(href) {
      if (href === 'practical-info.html') {
        this.$emit('show-practical-info', this.$i18n.locale);
        window.location.href = `${href}?locale=${this.$i18n.locale}`;
      } else if(href === 'faq.html') {
        this.$emit('show-faq', this.$i18n.locale);
        window.location.href = `${href}?locale=${this.$i18n.locale}`;
      } else {
        window.location.href = href;
      }
    },
    initializeButtonConfigs() {
      this.buttonConfigs = [
        {
          divClasses: "flex flex-col justify-center items-center p-6",
          buttonClasses: "flex flex-col items-center drop-shadow-2xl transition ease-in-out delay-50 hover:-translate-y-1 hover:scale-110 duration-300 justify-center feathered-circle hover:bg-rose-100 rounded-full p-6 w-28 h-28",
          imgSrc: require('@/assets/Invitation1.png'),
          imgAlt: "Invitation Icon",
          imgClasses: "w-full max-w-xs drop-shadow-2xl",
          spanClasses: "page-color-text md:text-lg lg:text-lg mt-2",
          text: this.$t('Invitation'),
          clickEvent: "handleClick",
          isLink: false,
          href: '',
          title: ''
        },
        {
          divClasses: "flex flex-col justify-center items-center p-6",
          buttonClasses: "flex flex-col items-center drop-shadow-2xl transition ease-in-out delay-50 hover:-translate-y-1 hover:scale-110 duration-300 justify-center feathered-circle hover:bg-rose-100 rounded-full p-6 w-28 h-28",
          imgSrc: require('@/assets/Address1.png'),
          imgAlt: "Address Icon",
          imgClasses: "w-full max-w-xs drop-shadow-2xl",
          spanClasses: "page-color-text md:text-lg lg:text-lg mt-2",
          text: this.$t('Address'),
          clickEvent: "showMap",
          isLink: false,
          href: '',
          title: ''
        },
        {
          divClasses: "flex flex-col justify-center items-center p-6",
          buttonClasses: "flex flex-col items-center drop-shadow-2xl transition ease-in-out delay-50 hover:-translate-y-1 hover:scale-110 duration-300 justify-center feathered-circle hover:bg-rose-100 rounded-full p-6 w-28 h-28",
          imgSrc: require('@/assets/Media1.png'),
          imgAlt: "Media Icon",
          imgClasses: "w-full max-w-xs drop-shadow-2xl",
          spanClasses: "page-color-text md:text-lg lg:text-lg mt-2",
          text: this.$t('Media'),
          clickEvent: "handleClickMedia",
          isLink: false,
          href: '',
          title: 'Media will be available after the wedding'
        },
        {
          divClasses: "flex flex-col justify-center items-center p-6",
          buttonClasses: "flex flex-col items-center drop-shadow-2xl transition ease-in-out delay-50 hover:-translate-y-1 hover:scale-110 duration-300 justify-center feathered-circle hover:bg-rose-100 rounded-full p-6 w-28 h-28",
          imgSrc: require('@/assets/WishList1.png'),
          imgAlt: "Wish List Icon",
          imgClasses: "w-full max-w-xs drop-shadow-2xl",
          spanClasses: "page-color-text md:text-lg lg:text-lg mt-2",
          text: this.$t('Wish-List'),
          clickEvent: "showWishList",
          isLink: false,
          href: '',
          title: ''
        }
      ],
      this.buttonConfigs2 = [
        {
          divClasses: "flex flex-col justify-center items-center p-6",
          buttonClasses: "flex flex-col items-center drop-shadow-2xl transition ease-in-out delay-50 hover:-translate-y-1 hover:scale-110 duration-300 justify-center feathered-circle hover:bg-rose-100 rounded-full p-6 w-28 h-28",
          imgSrc: require('@/assets/DressCode.png'),
          imgAlt: "Dress Code",
          imgClasses: "w-full max-w-xs drop-shadow-2xl",
          spanClasses: "page-color-text md:text-lg lg:text-lg mt-2",
          text: this.$t('Dress-Code'),
          clickEvent: "showDressCode",
          isLink: false,
          href: '',
          title: ''
        },

        {
          divClasses: "flex flex-col justify-center items-center p-6",
          buttonClasses: "flex flex-col items-center drop-shadow-2xl transition ease-in-out delay-50 hover:-translate-y-1 hover:scale-110 duration-300 justify-center feathered-circle hover:bg-rose-100 rounded-full p-6 w-28 h-28",
          imgSrc: require('@/assets/PracticalInfo.png'),
          imgAlt: "Practical Info Icon",
          imgClasses: "w-full max-w-xs drop-shadow-2xl",
          spanClasses: "page-color-text text-base md:text-lg lg:text-lg mt-2",
          text: this.$t('Practical-Info'),
          isLink: true,
          href: 'practical-info.html',
          clickEvent: "saveScrollPosition",
          title: ''
        },

        {
          divClasses: "flex flex-col justify-center items-center p-6",
          buttonClasses: "flex flex-col items-center drop-shadow-2xl transition ease-in-out delay-50 hover:-translate-y-1 hover:scale-110 duration-300 justify-center feathered-circle hover:bg-rose-100 rounded-full p-6 w-28 h-28",
          imgSrc: require('@/assets/FAQ.png'),
          imgAlt: "FAQ Icon",
          imgClasses: "w-full max-w-xs drop-shadow-2xl",
          spanClasses: "page-color-text md:text-lg lg:text-lg mt-2",
          text: this.$t('FAQ'),
          clickEvent: "saveScrollPosition",
          isLink: true,
          href: 'faq.html',
          title: ''
        },

        {
          divClasses: "flex flex-col justify-center items-center p-6",
          buttonClasses: "flex flex-col items-center drop-shadow-2xl transition ease-in-out delay-50 hover:-translate-y-1 hover:scale-110 duration-300 justify-center feathered-circle hover:bg-rose-100 rounded-full p-6 w-28 h-28",
          imgSrc: require('@/assets/Contact.png'),
          imgAlt: "Contact Icon",
          imgClasses: "w-full max-w-xs drop-shadow-2xl",
          spanClasses: "page-color-text md:text-lg lg:text-lg",
          text: this.$t('Contact'),
          clickEvent: "showContact",
          isLink: false,
          href: '',
          title: ''
        }
      ]
    }
  }
}
</script>

<style scoped>
/* Basic tooltip styling */
[title] {
  position: relative;
}

[title]::before {
  content: attr(title);
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 0.5em;
  border-radius: 0.3em;
  white-space: nowrap;
  z-index: 10;
  opacity: 0;
  transition: opacity 0.3s;
}

[title]:hover::before {
  opacity: 1;
}
</style>
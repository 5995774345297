<template>
  <link rel="stylesheet" href="//unpkg.com/leaflet@1.7.1/dist/leaflet.css" />
    <div>
      <Language v-if="currentPage === 'language'" @language-selected="showHome"/>
      <Home v-if="currentPage === 'home'"/>
    </div>
</template>

<script>
import Home from './views/Home.vue'
import Language from './views/Language.vue'

export default {
  name: 'App',
  components: {
    Language,
    Home,
  },
  data(){
    return {
      currentPage: 'language' //default page for first time users
    };
  },
  created(){
    const savedLanguage = localStorage.getItem('selectedLanguage');
    if(savedLanguage){
      this.$i18n.locale = savedLanguage;
      this.currentPage = 'home';
    }
  },
  methods: {
    showHome(){
      this.currentPage = 'home';
    },
    setPage(page){
      console.log(page)
      this.currentPage = page;
    }
  }
};
</script>

<style>
  @import 'tailwindcss/base';
  @import 'tailwindcss/components';
  @import 'tailwindcss/utilities';  
  #app{
  text-align: center;
  }
  body {
  background-color: #d1d6c7;
}
</style>
